import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: true,
  data: [],
  error: '',
  total: 0,
}

const practitionerPetownerSlice = createSlice({
  name: 'practitionerPetownerList',
  initialState,
  reducers: {
    loaderListner: (state, action) => {
      state.loading = action.payload.loading
    },
    successPractitionerPetownerReducer: (state, action) => {
      state.loading = action.payload.loading;
      state.data = action.payload.data;
      state.total = action.payload.total;
    },
    failedPractitionerPetownerReducer: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const { successPractitionerPetownerReducer, failedPractitionerPetownerReducer, loaderListner } = practitionerPetownerSlice.actions

export default practitionerPetownerSlice.reducer
