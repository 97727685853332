import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: true,
    data: [],
    error: '',
    total: 0,
};
const practitionerProductSlice = createSlice({
    name: 'practitionerProductList',
    initialState,
    reducers: {
        loaderListner: (state, action) => {
            state.loading = action.payload.loading;
        },
        successPractitionerProductReducer: (state, action) => {
            state.loading = action.payload.loading;
            state.data = action.payload.data;
            state.total = action.payload.total;
        },
        failedPractitionerProductReducer: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
    },
});
export const { successPractitionerProductReducer, failedPractitionerProductReducer, loaderListner } = practitionerProductSlice.actions;

export default practitionerProductSlice.reducer;
