import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    protocolListData: [],
    speciesListData:[]
};
const protocolListDetailSlice = createSlice({
    name: 'selectprotocolDetailList',
    initialState,
    reducers: {
        protocolListReducer: (state, action) => {
            state.protocolListData = action.payload.protocolList;
        },
        speciesListReducer: (state, action) => {
            state.speciesListData = action.payload.speciesList;
        },
    },
});
export const { protocolListReducer, speciesListReducer } = protocolListDetailSlice.actions;

export default protocolListDetailSlice.reducer;
