import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: true,
  data: [],
  error: '',
  total: 0,
  pageNo: 0,
  limit: 0,
  totalPage: 0,
}

const petOwnerCurrentPrescriptionSlice = createSlice({
  name: 'petOwnerCurrentPrescriptionList',
  initialState,
  reducers: {
    loaderListner: (state, action) => {
      state.loading = action.payload.loading
    },
    successPetOwnerCurrentPrescriptionReducer: (state, action) => {
      state.loading = action.payload.loading;
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.limit = action.payload.limit;
      state.pageNo = action.payload.pageNo;
      state.totalPage = action.payload.totalPage;
    },
    failedPetOwnerCurrentPrescriptionReducer: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const { successPetOwnerCurrentPrescriptionReducer, failedPetOwnerCurrentPrescriptionReducer, loaderListner } = petOwnerCurrentPrescriptionSlice.actions

export default petOwnerCurrentPrescriptionSlice.reducer
