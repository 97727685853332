import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: true,
  data: [],
  error: '',
  total: 0,
  pageNo: 0,
  limit: 0,
  totalPage: 0,
}

const practitionerPrescriptionSlice = createSlice({
  name: 'practitionerPrescriptionList',
  initialState,
  reducers: {
    loaderListner: (state, action) => {
      state.loading = action.payload.loading
    },
    successPractitionerPrescriptionReducer: (state, action) => {
      state.loading = action.payload.loading;
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.limit = action.payload.limit;
      state.pageNo = action.payload.pageNo;
      state.totalPage = action.payload.totalPage;
    },
    failedPractitionerPrescriptionReducer: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const { successPractitionerPrescriptionReducer, failedPractitionerPrescriptionReducer, loaderListner } = practitionerPrescriptionSlice.actions

export default practitionerPrescriptionSlice.reducer
