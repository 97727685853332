import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: true,
    data: '',
    error: '',
};
const practitionerReplaceUploadImageDataSlice = createSlice({
    name: 'practitionerReplaceUploadImageDataList',
    initialState,
    reducers: {
        loaderListner: (state, action) => {
            state.loading = action.payload.loading;
        },
        successPractitionerReplaceUploadImageDataReducer: (state, action) => {
            state.data = action.payload.data;
        },
        failedPractitionerReplaceUploadImageDataReducer: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
    },
});
export const { successPractitionerReplaceUploadImageDataReducer, failedPractitionerReplaceUploadImageDataReducer, loaderListner } = practitionerReplaceUploadImageDataSlice.actions;

export default practitionerReplaceUploadImageDataSlice.reducer;
