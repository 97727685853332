import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    loading: true,
    data: [],
    error: [],
    total: 0,
    pageNo: 0,
    limit: 0,
    totalPage: 0
}

const adminProductSlice = createSlice({
    name: 'adminProductList',
    initialState,
    reducers: {
        loaderListener: (state, action) => {
            state.loading = action.payload.loading;
        },
        successAdminProductReducer: (state, action) => {
            state.loading = action.payload.loading;
            state.data = action.payload.data;
            state.total = action.payload.total;
            state.limit = action.payload.limit;
            state.pageNo = action.payload.pageNo;
            state.totalPage = action.payload.totalPage;
        },
        failedAdminProductReducer: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
});

export const { successAdminProductReducer, failedAdminProductReducer, loaderListener } = adminProductSlice.actions;

export default adminProductSlice.reducer;