import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: true,
    data: {},
    error: '',
};
const adminExceptionsDetailSlice = createSlice({
    name: 'adminExceptionsDetail',
    initialState,
    reducers: {
        loaderListner: (state, action) => {
            state.loading = action.payload.loading;
        },
        successAdminExceptionsDetailReducer: (state, action) => {
            state.loading = action.payload.loading;
            state.data = action.payload.data;
        },
        failedAdminExceptionsDetailReducer: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
    },
});
export const { successAdminExceptionsDetailReducer, failedAdminExceptionsDetailReducer, loaderListner } = adminExceptionsDetailSlice.actions;

export default adminExceptionsDetailSlice.reducer;
