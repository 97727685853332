import { Navigate } from 'react-router-dom'
import Utils from '../utils';

function PrivateRouteAdmin ({ children }) {
  const authAdmin = localStorage.getItem('accessToken');
  const roleAdmin = localStorage.getItem('role') == 'Employee';
  return authAdmin && roleAdmin ? children : <Navigate to={Utils.EndPoint.auth.loginAdmin} />
}

export default PrivateRouteAdmin
