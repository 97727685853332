import React from "react";
import { createBrowserRouter } from "react-router-dom";
import Utils from "../../src/utils";
import PrivateRouteAdmin from "./PrivateRouteAdmin";
import PrivateRoutePetOwner from "./PrivateRoutePetowner";
import PrivateRoutePractitioner from "./PrivateRoutePractitioner";

const TermsAndConditionsWrapper = React.lazy(
  async () => await import("../pages/common/TermsAndConditions")
);
const PrivacyPolicyWrapper = React.lazy(
  async () => await import("../pages/common/PrivacyPolicy")
);
const PageNotFoundWrapper = React.lazy(
  async () => await import("../../src/pages/PageNotFound")
);

const LoginWrapper = React.lazy(
  async () => await import("../../src/pages/Auth/login")
);
const ForgotPasswordWrapper = React.lazy(
  async () => await import("../../src/pages/Auth/ForgotPassword")
);
const ResetPasswordWrapper = React.lazy(
  async () => await import("../../src/pages/Auth/ResetPassword")
);
const SignupWrapper = React.lazy(
  async () => await import("../../src/pages/Auth/signup")
);
const AskQuestionWrapper = React.lazy(
  async () => await import("../../src/pages/AskQuestion")
);

const AdminHomeWrapper = React.lazy(
  async () => await import("../pages/Admin")
);
const AdminPractitionersWrapper = React.lazy(
  async () => await import('../pages/Admin/Practitioners')
);
const AdminPetOwnersWrapper = React.lazy(
  async () => await import("../pages/Admin/Petowners")
);
const AdminPrescriptionsWrapper = React.lazy(
  async () => await import("../pages/Admin/Prescriptions")
);
const AdminShipperWrapper = React.lazy(
  async () => await import("../pages/Admin/Shippers")
);
const AdminProductsWrapper = React.lazy(
  async () => await import("../pages/Admin/Products")
);
const AdminQuestionsWrapper = React.lazy(
  async () => await import("../pages/Admin/Questions")
);
const AdminProfileWrapper = React.lazy(
  async () => await import("../pages/Admin/Profile")
);



//practitioners
const PractitionerProductsWrapper = React.lazy(
  async () => await import("../../src/pages/Practitioner/Products")
);
const PractitionerPetOwnersWrapper = React.lazy(
  async () => await import("../../src/pages/Practitioner/PetOwners")
);
const PractitionerPrescriptionsWrapper = React.lazy(
  async () => await import("../../src/pages/Practitioner/Prescriptions")
);
const PractitionerQuestionWrapper = React.lazy(
  async () => await import("../../src/pages/Practitioner/Questions")
);
const PractitionerTermsOfUseWrapper = React.lazy(
  async () => await import("../pages/Practitioner/TermsOfUseWrapper")
);
const PractitionerPrivacyPolicyWrapper = React.lazy(
  async () => await import("../pages/Practitioner/PrivacyPolicyWrapper")
);
const PractitionerSubscriptionSuccessWrapper = React.lazy(
  async () => await import("../modules/Practitioner/Stripe/SubscriptionSuccess")
);
const PetOwnerHomesWrapper = React.lazy(
  async () => await import("../pages/PetOwner")
);
const PetOwnerPrescriptionsWrapper = React.lazy(
  async () => await import("../pages/PetOwner/Prescriptions")
);
const PetOwnerPostAcceptanceWrapper = React.lazy(
  async () => await import("../pages/PetOwner/Acceptance")
);
const PetOwnerProfileWrapper = React.lazy(
  async () => await import("../pages/PetOwner/Profile")
);
const PetOwnerRedirectWrapper = React.lazy(
  async () => await import("../pages/PetOwner/Redirect")
);

const adminRoutes = [
  {
    path: Utils.EndPoint.admin.home,
    element: (
      <PrivateRouteAdmin>
        <AdminHomeWrapper />
      </PrivateRouteAdmin>
    ),
    children: [
      {
        path: Utils.EndPoint.admin.practitioners,
        element: (
          <PrivateRouteAdmin>
            <AdminPractitionersWrapper />
          </PrivateRouteAdmin>
        )
      },
      {
        path: Utils.EndPoint.admin.petowners,
        element: (
          <PrivateRouteAdmin>
            <AdminPetOwnersWrapper />
          </PrivateRouteAdmin>
        )
      },
      {
        path: Utils.EndPoint.admin.prescriptions,
        element: (
          <PrivateRouteAdmin>
            <AdminPrescriptionsWrapper />
          </PrivateRouteAdmin>
        )
      },
      {
        path: Utils.EndPoint.admin.shipper,
        element: (
          <PrivateRouteAdmin>
            <AdminShipperWrapper />
          </PrivateRouteAdmin>
        )
      },
      {
        path: Utils.EndPoint.admin.products,
        element: (
          <PrivateRouteAdmin>
            <AdminProductsWrapper />
          </PrivateRouteAdmin>
        )
      },
      {
        path: Utils.EndPoint.admin.questions,
        element: (
          <PrivateRouteAdmin>
            <AdminQuestionsWrapper />
          </PrivateRouteAdmin>
        )
      },
    ]
  },
  {
    path: Utils.EndPoint.admin.profile,
    element: (
      <PrivateRouteAdmin>
        <AdminProfileWrapper />
      </PrivateRouteAdmin>
    ),
  }
];

const practitionerRoutes = [
  {
    path: Utils.EndPoint.Practitioner.PractitionerPetOwners,
    element: (
      <PrivateRoutePractitioner>
        <PractitionerPetOwnersWrapper />
      </PrivateRoutePractitioner>
    ),
  },
  {
    path: Utils.EndPoint.Practitioner.PractitionerPrescriptions,
    element: (
      <PrivateRoutePractitioner>
        <PractitionerPrescriptionsWrapper />
      </PrivateRoutePractitioner>
    ),
  },
  {
    path: Utils.EndPoint.Practitioner.PractitionerQuestions,
    element: (
      <PrivateRoutePractitioner>
        <PractitionerQuestionWrapper />
      </PrivateRoutePractitioner>
    ),
  },
  {
    path: Utils.EndPoint.Practitioner.PractitionerProducts,
    element: (
      <PrivateRoutePractitioner>
        <PractitionerProductsWrapper />
      </PrivateRoutePractitioner>
    ),
  },
  {
    path: Utils.EndPoint.Practitioner.PractitionerTermsOfUse,
    element: (
      <PrivateRoutePractitioner>
        <PractitionerTermsOfUseWrapper />
      </PrivateRoutePractitioner>
    ),
  },
  {
    path: Utils.EndPoint.Practitioner.PractitionerPrivacyPolicy,
    element: (
      <PrivateRoutePractitioner>
        <PractitionerPrivacyPolicyWrapper />
      </PrivateRoutePractitioner>
    ),
  },
  {
    path: Utils.EndPoint.Practitioner.PractitionerSubscriptionSuccess,
    element: (
      <PrivateRoutePractitioner>
        <PractitionerSubscriptionSuccessWrapper />
      </PrivateRoutePractitioner>
    ),
  },
];

const petOwnerRoutes = [
  {
    path: Utils.EndPoint.petOwner.home,
    element: (
      <PrivateRoutePetOwner>
        <PetOwnerHomesWrapper />
      </PrivateRoutePetOwner>
    ),
    children: [
      {
        path: Utils.EndPoint.petOwner.prescriptions,
        element: (
          <PrivateRoutePetOwner>
            <PetOwnerPrescriptionsWrapper />
          </PrivateRoutePetOwner>
        )
      },
      {
        path: Utils.EndPoint.petOwner.acceptance,
        element: (
          <PrivateRoutePetOwner>
            <PetOwnerPostAcceptanceWrapper />
          </PrivateRoutePetOwner>
        )
      },
    ]
  },
  {
    path: Utils.EndPoint.petOwner.profile,
    element: <PetOwnerProfileWrapper />,
  },
  {
    path: Utils.EndPoint.petOwner.redirect,
    element: <PetOwnerRedirectWrapper />,
  },
];

const authRoutes = [
  {
    path: Utils.EndPoint.auth.loginMRM,
    element: <LoginWrapper />,
  },
  {
    path: Utils.EndPoint.auth.loginAdminLayout,
    element: <LoginWrapper />,
  },
  {
    path: Utils.EndPoint.auth.loginPractitionerLayout,
    element: <LoginWrapper />,
  },
  {
    path: Utils.EndPoint.auth.loginPetownerLayout,
    element: <LoginWrapper />,
  },
  {
    path: Utils.EndPoint.auth.loginAdmin,
    element: <LoginWrapper />,
  },
  {
    path: Utils.EndPoint.auth.loginPractitioner,
    element: <LoginWrapper />,
  },
  {
    path: Utils.EndPoint.auth.loginPetowner,
    element: <LoginWrapper />,
  },
  {
    path: Utils.EndPoint.auth.forgotPasswordMRM,
    element: <ForgotPasswordWrapper />,
  },
  {
    path: Utils.EndPoint.auth.forgotPasswordAdmin,
    element: <ForgotPasswordWrapper />,
  },
  {
    path: Utils.EndPoint.auth.forgotPasswordPractitioner,
    element: <ForgotPasswordWrapper />,
  },
  {
    path: Utils.EndPoint.auth.forgotPasswordPetowner,
    element: <ForgotPasswordWrapper />,
  },
  {
    path: Utils.EndPoint.auth.resetPasswordAdmin,
    element: <ResetPasswordWrapper />,
  },
  {
    path: Utils.EndPoint.auth.resetPasswordPractitioner,
    element: <ResetPasswordWrapper />,
  },
  {
    path: Utils.EndPoint.auth.resetPasswordPetowner,
    element: <ResetPasswordWrapper />,
  },
  {
    path: Utils.EndPoint.auth.signupAdmin,
    element: <SignupWrapper />,
  },
  {
    path: Utils.EndPoint.auth.signupPractitioner,
    element: <SignupWrapper />,
  },
  {
    path: Utils.EndPoint.auth.signupPetowner,
    element: <SignupWrapper />,
  },
  {
    path: Utils.EndPoint.askQuestion,
    element: <AskQuestionWrapper />,
  },
]

const commonRoutes = [
  {
    path: Utils.EndPoint.privacy,
    element: <PrivacyPolicyWrapper />,
  },
  {
    path: Utils.EndPoint.petOwner.petOwnerTermsOfUse,
    element: <TermsAndConditionsWrapper />,
  },
  {
    path: Utils.EndPoint.notFound,
    element: <PageNotFoundWrapper />,
  },
];

let routes = [...authRoutes, ...commonRoutes, ...petOwnerRoutes, ...practitionerRoutes, ...adminRoutes];

const router = createBrowserRouter(routes);

export default router;
