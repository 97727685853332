import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: true,
    data: [],
    error: '',
};
const adminExceptionsCategoryListSlice = createSlice({
    name: 'adminExceptionsCategoryList',
    initialState,
    reducers: {
        loaderListner: (state, action) => {
            state.loading = action.payload.loading;
        },
        successAdminExceptionsCategoryListReducer: (state, action) => {
            state.loading = action.payload.loading;
            state.data = action.payload.data;
        },
        failedAdminExceptionsCategoryListReducer: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
    },
});
export const { successAdminExceptionsCategoryListReducer, failedAdminExceptionsCategoryListReducer, loaderListner } = adminExceptionsCategoryListSlice.actions;

export default adminExceptionsCategoryListSlice.reducer;
