import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: true,
    petownerProfileData: [],
    error: '',
};
const petownerDetailSlice = createSlice({
    name: 'petownerDetail',
    initialState,
    reducers: {
        loaderListner: (state, action) => {
            state.loading = action.payload.loading;
        },
        successPetownerDetailReducer: (state, action) => {
            state.loading = action.payload.loading;
            state.petownerProfileData = action.payload.data;
        },
        failedPetownerDetailReducer: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
    },
});
export const { successPetownerDetailReducer, failedPetownerDetailReducer, loaderListner } = petownerDetailSlice.actions;

export default petownerDetailSlice.reducer;
