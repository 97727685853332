import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    termsOfUseData: '',
    privacyPolicyData: '',
};
const complianceDetailSlice = createSlice({
    name: 'compliance',
    initialState,
    reducers: {
        loaderListner: (state, action) => {
            state.loading = action.payload.loading
        },
        termsOfUseReducer: (state, action) => {
            state.termsOfUseData = action.payload.termsOfUseData;
        },
        privacyPolicyReducer: (state, action) => {
            state.privacyPolicyData = action.payload.privacyPolicyData;
        },
    },
});
export const { loaderListner, termsOfUseReducer, privacyPolicyReducer } = complianceDetailSlice.actions;

export default complianceDetailSlice.reducer;
