import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    practitionerProfileData: [],
    subscriptionPlanData: [],
    subscriptionCalculateTaxData: {},
    error: '',
};
const practitionerDetailSlice = createSlice({
    name: 'practitionerDetailList',
    initialState,
    reducers: {
        loaderListner: (state, action) => {
            state.loading = action.payload.loading;
        },
        successPractitionerDetailReducer: (state, action) => {
            state.loading = action.payload.loading;
            state.practitionerProfileData = action.payload.data;
        },
        failedPractitionerDetailReducer: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
        subscriptionPlanListReducer: (state, action) => {
            state.subscriptionPlanData = action.payload.data;
        },
        // subscriptionCalculateTaxReducer: (state, action) => {
        //     state.subscriptionCalculateTaxData = action.payload.data;
        // },
    },
});
export const { successPractitionerDetailReducer, failedPractitionerDetailReducer, loaderListner, subscriptionPlanListReducer, subscriptionCalculateTaxReducer } = practitionerDetailSlice.actions;

export default practitionerDetailSlice.reducer;
