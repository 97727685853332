import { combineReducers, configureStore } from '@reduxjs/toolkit'
// We'll use redux-logger just as an example of adding another middleware
import logger from 'redux-logger'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
// import authSlice from './features/authSlice';
import authmodelSlice from '../modules/Auth/AuthSlice'
import practitionerProductSlice from '../modules/Practitioner/Products/Slice/practitionerProductSlice'
import practitionerPetownerSlice from '../modules/Practitioner/PetOwners/Slice/practitionerPetownerSlice'
import practitionerPetOwnerDetailSlice from '../modules/Practitioner/Component/DialogBox/PetOwnerDetail/Slice'
import practitionerPrescriptionSlice from '../modules/Practitioner/Prescriptions/Slice/practitionerPrescriptionSlice'
import practitionerPrescriptionDetailSlice from '../modules/Practitioner/Component/DialogBox/PrescriptionDetail/Slice'
import petOwnerCurrentPrescriptionSlice from '../modules/PetOwner/Prescriptions/Slice/petOwnerCurrentPrescriptionSlice'
import petOwnerPreviousPrescriptionSlice from '../modules/PetOwner/Prescriptions/Slice/petOwnerPreviousPrescriptionSlice'
import petOwnerPrescriptionDetailSlice from '../modules/PetOwner/Prescriptions/Slice/petOwnerPrescriptionDetailSlice'
import practitionerDetailSlice from '../modules/Practitioner/Component/DialogBox/PractitionerProfile/Slice'
import petownerDetailSlice from '../modules/PetOwner/Profile/Slice/petownerDetailSlice';
import adminPractitionerSlice from '../modules/Admin/Practitioners/Slice/adminPractitionerSlice';
import adminPetownerSlice from '../modules/Admin/Petowners/Slice/adminPetownerSlice';
import adminPrescriptionSlice from '../modules/Admin/Prescriptions/Slice/adminPrescriptionSlice';
import adminProductSlice from '../modules/Admin/Products/Slice/adminProductSlice';
import adminShipperSlice from '../modules/Admin/Shippers/Slice/adminShipperSlice';
import adminPractitionerDetailSlice from '../modules/Admin/Practitioners/Slice/adminPractitionerDetailSlice';
import adminPetownerDetailSlice from '../modules/Admin/Petowners/Slice/adminPetownerDetailSlice';
import practitionerReplaceUploadImageDataSlice from '../modules/Practitioner/Component/DialogBox/ReplaceUploadImageReport/Slice';
import protocolListDetailSlice from '../modules/Practitioner/Component/DialogBox/SelectProtocol/Slice';
import verifyPaymentSlice from '../modules/PetOwner/Acceptance/Slice/verifyPaymentSlice';
import shippingRatesSlice from '../modules/PetOwner/Acceptance/Slice/shippingRatesSlice';
import adminPrescriptionDetailSlice from '../modules/Admin/Prescriptions/Slice/adminPrescriptionDetailSlice';
import complianceDetailSlice from '../modules/Practitioner/Compliance/Slice'
import adminExceptionsCategoryListSlice from '../modules/Admin/dialogs/Slice/adminExceptionsCategoryListSlice';
import adminExceptionsTypeListSlice from '../modules/Admin/dialogs/Slice/adminExceptionsTypeListSlice';
import adminExceptionsDetailSlice from '../modules/Admin/dialogs/Slice/adminExceptionsDetailSlice';
import adminExceptionsListSlice from '../modules/Admin/dialogs/Slice/adminExceptionsListSlice';
import adminDevicesListSlice from '../modules/Admin/dialogs/Slice/adminDevicesListSlice';
import adminSalesPersonsListSlice from '../modules/Admin/dialogs/Slice/adminSalesPersonsListSlice'
import adminShipDevicesListSlice from '../modules/Admin/dialogs/Slice/adminShipDevicesListSlice'
import adminSubscriptionPlanListSlice from '../modules/Admin/Practitioners/Slice/adminSubscriptionPlanList'

const persistConfig = {
  debug: false,
  key: 'root',
  keyPrefix: 'v.1',
  storage,
  blacklist: [],
  // add reducer name to persist
  whitelist: ['auth']
}

// combine all reducers here
const rootReducer = combineReducers({
  // auth: authSlice,
  auth: authmodelSlice,
  productList_practitioner: practitionerProductSlice,
  petOwnerList_practitioner: practitionerPetownerSlice,
  petOwnerDetailList_practitioner: practitionerPetOwnerDetailSlice,
  prescription_practitioner: practitionerPrescriptionSlice,
  prescriptionDetail_practitioner: practitionerPrescriptionDetailSlice,
  practitionerDetail_practitioner: practitionerDetailSlice,
  practitionerDetail_replaceUploadImage: practitionerReplaceUploadImageDataSlice,
  protocolListData: protocolListDetailSlice,
  complianceDetailData: complianceDetailSlice,

  //admin
  product_admin: adminProductSlice,
  practitioner_admin: adminPractitionerSlice,
  petowner_admin: adminPetownerSlice,
  prescription_admin: adminPrescriptionSlice,
  shipper_admin: adminShipperSlice,
  practitionerDetail_admin: adminPractitionerDetailSlice,
  petownerDetail_admin: adminPetownerDetailSlice,
  prescriptionDetail_admin: adminPrescriptionDetailSlice,
  exceptionsCategoryList_admin: adminExceptionsCategoryListSlice,
  exceptionsTypeList_admin: adminExceptionsTypeListSlice,
  exceptionsDetail_admin: adminExceptionsDetailSlice,
  exceptions_admin: adminExceptionsListSlice,
  devices_admin: adminDevicesListSlice,
  salesPersons_admin: adminSalesPersonsListSlice,
  shipDevices_admin: adminShipDevicesListSlice,
  subscriptionPlanList_admin: adminSubscriptionPlanListSlice,
  //petowner
  currentPrescriptionList_petowner: petOwnerCurrentPrescriptionSlice,
  previousPrescriptionList_petowner: petOwnerPreviousPrescriptionSlice,
  prescriptionDetail_petowner: petOwnerPrescriptionDetailSlice,
  petownerDetail_petowner: petownerDetailSlice,
  verifyPayment_petowner: verifyPaymentSlice,
  shippingRates_petowner: shippingRatesSlice

})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(logger)
})

export const persistor = persistStore(store)