import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: true,
    data: [],
    error: '',
};
const adminSalesPersonsListSlice = createSlice({
    name: 'adminSalesPersonsList',
    initialState,
    reducers: {
        loaderListner: (state, action) => {
            state.loading = action.payload.loading;
        },
        successAdminSalesPersonsListReducer: (state, action) => {
            state.loading = action.payload.loading;
            state.data = action.payload.data;
        },
        failedAdminSalesPersonsListReducer: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
    },
});
export const { successAdminSalesPersonsListReducer, failedAdminSalesPersonsListReducer, loaderListner } = adminSalesPersonsListSlice.actions;

export default adminSalesPersonsListSlice.reducer;
