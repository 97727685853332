import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: true,
    data: {},
    error: '',
};
const verifyPaymentSlice = createSlice({
    name: 'shippingRatesSlice',
    initialState,
    reducers: {
        loaderListner: (state, action) => {
            state.loading = action.payload.loading
        },
        successShippingRatesSliceReducer: (state, action) => {
            state.loading = action.payload.loading;
            state.data = action.payload.data;
            state.error = '';
        },
        failedShippingRatesSliceReducer: (state, action) => {
            state.loading = false;
            state.error = action.payload;
            state.data = {};
        },
    },
});
export const { successShippingRatesSliceReducer, failedShippingRatesSliceReducer, loaderListner } = verifyPaymentSlice.actions;

export default verifyPaymentSlice.reducer;
