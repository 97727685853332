import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: true,
    data: [],
    error: '',
};
const adminSubscriptionPlanListSlice = createSlice({
    name: 'adminSubscriptionPlanListDetail',
    initialState,
    reducers: {
        loaderListner: (state, action) => {
            state.loading = action.payload.loading
        },
        successAdminSubscriptionPlanListSliceReducer: (state, action) => {
            state.loading = action.payload.loading;
            state.data = action.payload.data;
        },
        failedAdminSubscriptionPlanListSliceReducer: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },

    },
});
export const { successAdminSubscriptionPlanListSliceReducer, failedAdminSubscriptionPlanListSliceReducer, loaderListner } = adminSubscriptionPlanListSlice.actions;

export default adminSubscriptionPlanListSlice.reducer;
