const adminEndpoints = {
  //routes url
  home: '/admin',
  practitioners: '/admin/practitioners',
  petowners: '/admin/petowners',
  prescriptions: '/admin/prescriptions',
  shipper: '/admin/shippers',
  products: '/admin/products',
  questions: '/admin/questions',
  profile: '/admin/profile',
  // Api url
  adminProductListApi: '/master-data/device-list/',
  adminPractitionerInviteApi: '/practitioner/practitionerinvite/',
  adminPractitionerListApi: '/practitioner/practitioner-list/',
  adminPetownerListApi: '/pet-owner/petowner-list/',
  adminPrescriptionListApi: '/practitioner/prescription-list/',
  adminPrescriptionDetailApi: '/practitioner/prescription-details/',
  adminShipperListApi: '/',
  adminPractitionerDetailApi: '/practitioner/practitioner-details/',
  adminUpdatePractitionerDetailApi: '/practitioner/modify-practitioner-details/',
  adminPetownerDetailApi: '/pet-owner/petowner-details/',
  adminUpdatePetownerDetailApi: '/pet-owner/modify-petowner-details/',
  adminCreateExceptionsApi: '/employee/create-exception/',
  adminCloseExceptionsApi: '/employee/close-exception/',
  adminExceptionsDetailApi: '/employee/exception-details/',
  adminExceptionsCategoryListApi: '/master-data/exception-category-list/',
  adminExceptionsTypeListApi: '/master-data/exception-type-list/',
  adminExceptionsListApi: '/employee/exception-list/',
  adminCreateCommentApi: '/employee/add-comment/',
  adminDevicesApi: '/master-data/device-to-ship/',
  adminShipDeviceApi: '/pet-owner/create-shipment/',
  adminReturnDeviceApi: '/employee/return-device/',
  adminRemoveDeviceApi: '/employee/remove-device/',
  adminReceiveDeviceApi: '/employee/receive-device/',
  adminReplaceDeviceApi: '/employee/replace-device/',
  adminSalesPersonsList: '/employee/sales-persons-list/',
  adminConfirmCompleteShipment: '/pet-owner/confirm-complete-shipment/',
  adminSubscriptionPlanList: '/master-data/subscription-plan-list/',
  adminShipmentLabels: '/employee/retrieve-shipment-labels/',
  adminResendInviteEmail: '/employee/resend-invite-email/',
}

const practitionerEndpoints = {
  PractitionerProducts: '/practitioner/product',
  PractitionerPetOwners: '/practitioner/petowner',
  PractitionerPrescriptions: '/practitioner/prescription',
  PractitionerQuestions: '/practitioner/question',
  PractitionerPrivacyPolicy: '/practitioner/privacy-policy',
  PractitionerSubscriptionSuccess: '/practitioner/success/',
  PractitionerTermsOfUse: 'https://info.multiradiance.com/vet/myrxlaser/practitioner/terms-conditions',
  PractitionerUsermanual: ' https://info.multiradiance.com/hubfs/My%20Rx%20Laser/MyRxLaser%20User%20Manual%20-%20Practitioners.pdf',
  PractitionerQuickRef: 'https://info.multiradiance.com/hubfs/My%20Rx%20Laser/MyRxLaser-QuickReferenceGuide_Practitioner.pdf',
  // Api Url
  practitionerProductsApi: '/master-data/device-list/',
  practitionerPetOwnersApi: '/pet-owner/petowner-list/',
  practitionerPetOwnerDetailApi: '/pet-owner/petowner-details/',
  practitionerPrescriptionListApi: '/practitioner/prescription-list/',
  practitionerPrescriptionDetailApi: '/practitioner/prescription-details/',
  practitionerPetOwnerInviteApi: '/pet-owner/petownerinvite/',
  practitionerSelectProtocolApi: '/practitioner/create-prescription/',
  practitionerDetailApi: '/practitioner/practitioner-details/',
  practitionerModifyPractitionerDetailApi: '/practitioner/modify-practitioner-details/',
  practitionerChangeProfileApi: '/practitioner/change-profile/',
  practitionerUnsubscribe: '/unsubscribe',
  practitionerReplaceImageReport: '/practitioner/replace-imaging-report/',
  practitionerStripeOnboarding: '/practitioner/generate-stripe-onboarding-link/',
  // practitionerInvitePetowner: '/invitepetowner',
  // practitionerSelectProtocol: '/selectProtocol',
  practitionerProtocolList: '/master-data/protocol-list/',
  practitionerSpecieList: '/master-data/specie-list/',
  practitionerPrivacyPolicyApi: '/api/v1/privacy-policy/',
  practitionerTermsOfUseApi: '/api/v1/practitioner-terms-conditions/',
  practitionerSubscriptionCalculateTax: '/practitioner/calculate-tax/',
  practitionerSubscriptionPlanList: '/master-data/subscription-plan-list/',
  practitionerCreateSubscription: '/practitioner/create-subscription/',
  practitionerRequestUnsubscribe: '/practitioner/request-unsubscribe/',
  practitionerconfirmSubscriptionPayment: '/practitioner/confirm-subscription-payment/',
}

const petownerEndpoints = {
  //routes url
  home: '/petOwner',
  prescriptions: '/petOwner/prescriptions',
  acceptance: '/petOwner/acceptance',
  profile: '/petOwner/profile',
  redirect: '/petOwner/redirect',
  petOwnerTermsOfUse: 'https://info.multiradiance.com/vet/myrxlaser/petowner/terms-conditions',
  petOwnerUsermanual: 'https://info.multiradiance.com/hubfs/My%20Rx%20Laser/MyRxLaser%20User%20Manual%20-%20Pet%20Owner.pdf',
  petOwnerQuickRef: 'https://info.multiradiance.com/hubfs/My%20Rx%20Laser/MyRxLaser-QuickReferenceGuide_PetOwner.pdf',
  petOwnerPrivacyPolicy: '/privacy',

  //Api url
  petOwnertPrescriptionListApi: '/practitioner/prescription-list/',
  petOwnertPrescriptionDetailApi: '/practitioner/prescription-details/',
  petownerDetailApi: '/pet-owner/petowner-details/',
  petownerUpdateDetailApi: '/pet-owner/modify-petowner-details/',
  petownerMakePaymentApi: '/pet-owner/make-payment/',
  petownerCalculateTaxApi: '/pet-owner/calculate-tax/',
  petownerConfirmPaymentApi: '/pet-owner/confirm-payment/',
  petOwnerShippingRatesApi: '/pet-owner/retrieve-shipping-rates/'
}

const EndPoint = {
  notFound: '/*',
  terms: '/terms',
  privacy: '/privacy',
  askQuestion: '/ask-question',
  auth: {
    loginMRM: '/',
    loginAdminLayout: '/employee',
    loginPractitionerLayout: '/practitioner',
    loginPetownerLayout: '/petowner',

    loginAdmin: '/employee/login',
    loginPractitioner: '/practitioner/login',
    loginPetowner: '/petowner/login',

    forgotPasswordMRM: '/forgot-password',
    forgotPasswordAdmin: '/employee/forgot-password',
    forgotPasswordPractitioner: '/practitioner/forgot-password',
    forgotPasswordPetowner: '/petowner/forgot-password',

    resetPasswordPractitioner: '/practitioner/reset-password/:token',
    resetPasswordAdmin: '/employee/reset-password/:token',
    resetPasswordPetowner: '/petowner/reset-password/:token',

    signupAdmin: '/employee/signup/:gdy/:email',
    signupPractitioner: '/practitioner/signup/:gdy/:email',
    signupPetowner: '/petowner/signup/:gdy/:email',

    // API URL
    loginApi: '/api/v1/login/',
    forgotPasswordApi: '/api/v1/sendresetpasswordemail/',
    changePasswordApi: '/api/v1/changepassword/',
    resetPasswordApi: '/api/v1/resetpassword/',
    signupApi: '/api/v1/signup/',
    checkEmailApi: '/api/v1/check-email/',

    // signin: '/signin',
    // admin_signup: 'admin/signup',
    // practitioner_signup: 'practitioner/signup',
    // user_signup: 'user/signup',
    // user_forgot_password: 'user/forgot-password',
  },
  admin: {
    ...adminEndpoints
  },
  Practitioner: {
    ...practitionerEndpoints
  },
  petOwner: {
    ...petownerEndpoints
  }
}

export default EndPoint

