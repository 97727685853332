import { Navigate } from 'react-router-dom'
import Utils from '../utils';

function PrivateRoutePetOwner({ children }) {
  const authPetowner = localStorage.getItem('accessToken');
  const rolePetowner = localStorage.getItem('role') == 'PetOwner';
  return authPetowner && rolePetowner ? children : <Navigate to={Utils.EndPoint.auth.loginPetowner} />
}

export default PrivateRoutePetOwner
