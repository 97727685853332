import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: false,
    data: {},
    error: '',
};
const adminPrescriptionDetailSlice = createSlice({
    name: 'adminPrescriptionDetailList',
    initialState,
    reducers: {
        loaderListener: (state, action) => {
            state.loading = action.payload.loading;
        },
        successAdminPrescriptionDetailReducer: (state, action) => {
            state.loading = action.payload.loading;
            state.data = action.payload.data;
        },
        failedAdminPrescriptionDetailReducer: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
    },
});
export const { successAdminPrescriptionDetailReducer, failedAdminPrescriptionDetailReducer, loaderListener } = adminPrescriptionDetailSlice.actions;

export default adminPrescriptionDetailSlice.reducer;
