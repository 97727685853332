import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loading: true,
  data: {},
  error: ''
}

const petOwnerPrescriptionDetailSlice = createSlice({
  name: 'petOwnerPrescriptionDetailList',
  initialState,
  reducers: {
    loaderListner: (state, action) => {
      state.loading = action.payload.loading
    },
    successPetOwnerPrescriptionDetailReducer: (state, action) => {
      state.loading = action.payload.loading;
      state.data = action.payload.data;
      state.total = action.payload.total;
      state.limit = action.payload.limit;
      state.pageNo = action.payload.pageNo;
      state.totalPage = action.payload.totalPage;
    },
    failedPetOwnerPrescriptionDetailReducer: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
  }
})

// Action creators are generated for each case reducer function
export const { successPetOwnerPrescriptionDetailReducer, failedPetOwnerPrescriptionDetailReducer, loaderListner } = petOwnerPrescriptionDetailSlice.actions

export default petOwnerPrescriptionDetailSlice.reducer
