import { Navigate } from 'react-router-dom'
import Utils from '../utils';

function PrivateRoutePractitioner({ children }) {
  const authPractitioner = localStorage.getItem('accessToken');
  const rolePractitioner = localStorage.getItem('role') == 'Practitioner';
  return authPractitioner && rolePractitioner ? children : <Navigate to={Utils.EndPoint.auth.loginPractitioner} />
}

export default PrivateRoutePractitioner
