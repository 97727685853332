import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: true,
    shipdata: {},
    error: '',
};
const adminShipDevicesListSlice = createSlice({
    name: 'adminShipDeviceList',
    initialState,
    reducers: {
        loaderListener: (state, action) => {
            state.loading = action.payload.loading;
        },
        successAdminShipDevicesListSliceReducer: (state, action) => {
            state.shipdata = action.payload.data;
        },
        failedAdminShipDevicesListSliceReducer: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
    },
});
export const { successAdminShipDevicesListSliceReducer, failedAdminShipDevicesListSliceReducer, loaderListener } = adminShipDevicesListSlice.actions;

export default adminShipDevicesListSlice.reducer;
