import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    loading: true,
    data: [],
    error: '',
    total: 0,
    pageNo: 0,
    limit: 0,
    totalPage: 0,
};
const adminPractitionerDetailSlice = createSlice({
    name: 'adminPractitionerDetailList',
    initialState,
    reducers: {
        loaderListner: (state, action) => {
            state.loading = action.payload.loading;
        },
        successAdminPractitionerDetailReducer: (state, action) => {
            state.loading = action.payload.loading;
            state.data = action.payload.data;
            state.total = action.payload.total;
            state.limit = action.payload.limit;
            state.pageNo = action.payload.pageNo;
            state.totalPage = action.payload.totalPage;
        },
        failedAdminPractitionerDetailReducer: (state, action) => {
            state.error = action.payload;
            state.loading = false;
        },
    },
});
export const { successAdminPractitionerDetailReducer, failedAdminPractitionerDetailReducer, loaderListner } = adminPractitionerDetailSlice.actions;

export default adminPractitionerDetailSlice.reducer;
